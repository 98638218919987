import React, { Component } from 'react';
import './App.css';

class HorizontalMediaContainer extends Component {

  constructor(props) {
    super(props);
    this.state = {
      imgAnimation: null,
      isTriggerLighBox: false
    };
  };

  componentDidMount(){
    this.targetElement = document.querySelector("#body");
    if(this.props){

      const animations = [
        "radius_animation_1",
        "radius_animation_2",
        "radius_animation_3"
      ]

      this.setState({
        imgAnimation: animations[Math.floor(
          Math.random()*animations.length)]
      })
    }
  }

  isTriggerLighBox = () => {
    return this.props.isTriggerLighBox()
  }


  renderVideoPreview = (gifLink) => {
    return(
      <div
        onClick={this.isTriggerLighBox}
        className="video_container_img_preview">
        <img
          onClick={this.isTriggerLighBox}
          src={gifLink}
          alt={gifLink}
        />
      <span className="video_cta">
        Voir la vidéo
      </span>
      </div>
    )
  }



  renderHorizontalMedia = () => {
    if(!this.props){
      return (
        <div>loading</div>
      )
    }else{
      const link = this.props.dataLink;
      if(link.includes(".jpg")){
        return (
          <div className="horizontal_img_container">
           <img
             className={this.state.imgAnimation}
             src={link}
             alt={link}
           />
           <div>
           </div>
         </div>
        )
      }else if(link.includes(".gif")){
        const dataLinkToArray = this.props.dataLink.split(".gif");
        const gifLink = `${dataLinkToArray[0]}.gif`;
        return (
          <div
            className="video_container">
           {this.renderVideoPreview(gifLink)}
         </div>
        )
    }else{
      return null;
    }
  }
}

  render() {
    if(!this.state.imgAnimation){
    return (
        <div>
          loading
        </div>
    )
  }else{
    return (
        <div>
          {this.renderHorizontalMedia()}
        </div>
    )
  }
  }
}

export default HorizontalMediaContainer;
