import React, { Component } from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import axios from 'axios';
import Home from "./Home.js";
import './App.css';

class SeeInfo extends Component {

  constructor(props) {
    super(props);
    this.state = {
      formattedInfoSpans: null,
      info_page: null,
      isEnglish: false,
    };
  };

  componentDidMount(){

     axios.get("https://sheet.best/api/sheets/a67fbb18-7803-4beb-a19a-e11c1623eba0")
     .then((response) => {
       const data = response.data;
       this.setState({
         data,
         testData: data[0]
       }, () => {
         const colors = data.filter((ele) => {return ele.website_section === "random_color_value"});
         const randomColorValueArray = colors[0].project_name.split("¬");
         this.setState({
          info_page: data.filter((ele) => {return ele.website_section === "info_page"}),
        }, () => {
          const additionalInfo = this.state.info_page[0].other_texts.split("¬")
          this.setState({
            additionalInfo
          })
        })
      })
     })
     .catch(function (error) {
       console.log(error);
     });
  };


  toggleFrench = () => {
    this.setState({
      isEnglish: false
    })
  };

  toggleEnglish = () => {
    this.setState({
      isEnglish: true
    })
  };

  toggleMainText = () => {

   const mainText = this.state.info_page[0].description;
   const mainTextEn = this.state.info_page[0].description_en;

   if(this.state.isEnglish){
     return mainTextEn;
   }else{
     return mainText;
   }
 };


  toggleBioLanguage = () => {
    if(this.state.isEnglish){
      return(
        <section
          onClick={this.toggleFrench}
          className="toggle_bio">
          <span>See french bio</span>
        </section>
      )
    }else{
      return(
        <section
          onClick={this.toggleEnglish}
          className="toggle_bio">
          <span>See english bio</span>
        </section>
      )
    }
  };


  renderBouncingBall = () => {
    if(!this.props){
      return (
        <div>loading</div>
      )
    }else{
      return(
        <div>
          <Link to="/">
          <div
            onTouchStart={this.props.triggerSeeInfoSection}
            onClick={this.props.triggerSeeInfoSection}
            className="box">
              <b style={{backgroundColor: "white"}}/>
          </div>
          </Link>
        </div>

      )
    }
  };

  renderMobileDesign = () => {
  if(this.props.width < 600){
    return (
      <div
        className="close_info_mobile">
        <Link to="/">
          Close bio
        </Link>
      </div>
    )
  }else{
    return null;
  }
};



  returnInfo = () => {

    if(!this.state.additionalInfo){
      return null
    };

    const additionalInfo = this.state.additionalInfo;
     const mainText = this.state.info_page[0].description;
     const mailTo = `mailto:${additionalInfo[3]}`;

    return (
      <div className="see_info_container">
             {this.renderBouncingBall()}

             <div className="see_info_page">
               <section className="see_info_page_details">
                 <div className="see_info_border_top">
                   <p>BIO</p>
                   <p>
                     <Link to="/">Close bio</Link>
                   </p>
                 </div>
                 <section className="toggle_see_info">
                 </section>
                 {this.renderMobileDesign()}
                 <h2>{additionalInfo[0]}</h2>
                 <h2>{additionalInfo[1]}</h2>
               </section>
               <h1>{this.toggleMainText()}</h1>
               <section className="see_info_mail_section">
                 <h2>{additionalInfo[2]}</h2>
                 <h2><a href={mailTo}>{additionalInfo[3]}</a></h2>
               </section>
               {this.toggleBioLanguage()}
               {this.closeBioOnMobile()}
             </div>
       </div>
    )
  }


  closeBioOnMobile = () => {
    return(
      <div className="close_bio_mobile">
        <Link to="/">
          <span>Close bio</span>
        </Link>
      </div>
    )
  }


  render() {
      return (
        <div>
          <Home />
          {this.returnInfo()}
        </div>
      )
  }
}

export default SeeInfo;
