import React, { Component } from 'react';
import Clock from 'react-live-clock';
import axios from 'axios';
import Vimeo from '@u-wave/react-vimeo';
import YouTube from '@u-wave/react-youtube';
import {BottomScrollListener} from 'react-bottom-scroll-listener';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import { HashLink as Link } from 'react-router-hash-link';
import HorizontalScroll from 'react-scroll-horizontal'
import HorizontalMediaContainer from './HorizontalMediaContainer';
import Header from './Header';
import HomePage from "./HomePage"
import Footer from './Footer';
import SeeInfo from "./SeeInfo";
import SeeMenu from "./SeeMenu"
import credentials from "./credentials.js"
import './App.css';

class HorizontalPage extends Component {


  constructor(props) {
    super(props);
    this.state = {
      time: new Date().toLocaleString(),
      selectedProjectData: null,
      width: null,
      selectedParam: null,
      renderProjectInfo: true,
      projectCredits: null,
      projectMedias: null,
      projectVideoLink: null,
      projectVideo: null,
      renderLighBox: false,
      isProjectPageLocked: false,
      isDisplayedProjectInfo: true,
      selectedRandomColor: null,
      isOnimagesSectionContent: false,
      displayedImagesArray: null,
      isSeeMenu: false,
      displayedImagesArray: null,
      displayedImage: null,
      displayedImageSpecs: null,
      infoPage: null,
      animsApis: [
        {scale: "scale(0.5)", animation: "6s ease 0s infinite normal none running cirle_anim"},
        {scale: "scale(0.3)", animation: "8s ease 0s infinite normal none running polygon_anim"},
        {scale: "scale(0.4)", animation: "4s ease 0s infinite normal none running clip"},
        {scale: "scale(0.6)", animation: "6s ease 0s infinite normal none running cirle_anim"},
        {scale: "scale(0.3)", animation: "2s ease 0s infinite normal none running polygon_anim"},
        {scale: "scale(0.5)", animation: "4s ease 0s infinite normal none running clip"}
      ]
    };
  };

  componentDidMount(){
    let selectedParam = this.props.match.params.project;


    window.addEventListener('resize', this.updateWindowDimensions);
    this.updateWindowDimensions();

    axios.get("https://sheet.best/api/sheets/a67fbb18-7803-4beb-a19a-e11c1623eba0")
    .then((response) => {
      const data = response.data;
      let selectedProjectData = data.filter((ele) => { return ele.project_url === selectedParam })
      const colors = data.filter((ele) => {return ele.website_section === "random_color_value"});
      const indexPage = data.filter((ele) => {return ele.website_section === "index_page"});
      const infoPage = data.filter((ele) => {return ele.website_section === "info_page"});
      const randomColorValueArray = colors[0].project_name.split("¬");
      let randomBackgroundColor = randomColorValueArray[Math.floor(randomColorValueArray.length * Math.random())];

      const randomImgStyling = this.state.animsApis.map((ele, index) => {
        return ele.animation
      })

      const displayedImagesArray = indexPage.map((ele, index) => {
        let imgStyling = randomImgStyling[Math.floor(randomImgStyling.length * Math.random())];
        return {
          img: ele.imgoftheprojecttofeature,
          specs: ele.specsoftheimgtofeature,
          animation: imgStyling
        }
      })

      this.setState({
        displayedImagesArray,
        selectedProjectData,
        selectedParam,
        indexPage,
        infoPage,
        selectedRandomColor: randomBackgroundColor
      }, () => {
        return this.renderPage();
      })
    })
    .catch(function (error) {
      console.log(error);
    });
    this.setState({
      renderLighBox: false,
      renderProjectInfo: true,
    })
    window.scrollTo(0,0);
  };

  updateWindowDimensions = () => {
    this.setState({
      width: window.innerWidth
    });
  };



  renderPage = () => {

    const selectedData = this.state.selectedProjectData[0];
    const projectCreditsArray = [];
    const projectMediasArray = [];
    let projectVideoLink = "";

    const projectCredits = selectedData.credit
    .split("¬")
    .map((ele, index) => {
      return(
        projectCreditsArray.push(ele)
      )
    })

    const projectMedias = selectedData.img_link
    .split("¬")
    .map((ele, index) => {
      if(ele.includes("cloudinary")){
        return(
          projectMediasArray.push(ele)
        )
      }
      else{
        return (
          projectVideoLink = ele
        )
      }
      })

    this.setState({
      projectCredits: projectCreditsArray,
      projectMedias: projectMediasArray,
      projectVideoLink: projectVideoLink
    })
  }

  renderProjectInfo = () => {
    const selectedData = this.state.selectedProjectData[0];

    const style = {
      backgroundColor: this.state.selectedRandomColor
    }
    if(this.state.isDisplayedProjectInfo === true){
      return (
        <section
          style={style}
          className="project_presentation_container">
          <section className="project_presentation">
            <div>
              <h1 style={{color: this.state.selectedRandomColor}}>
                {selectedData.project_name}
              </h1>
              <h2 style={{color: this.state.selectedRandomColor}}>
                {selectedData.project_type}
              </h2>
              {this.renderProjectMainSubtitles()}
            </div>
            <div>
              {this.renderProjectCredit(this.selectedData)}
            </div>
          </section>
        </section>
      )
    }else{
      return null;
    }
  }

  renderProjectMainSubtitles = () => {

    const selectedData = this.state.selectedProjectData[0];

    let selectedDataProjetMainSubtitle = selectedData.project_main_subtitle;
    if(selectedDataProjetMainSubtitle.length > 0){
      return (
        <h3>{selectedDataProjetMainSubtitle}</h3>
      )
    }else{
      return null;
    }
  }

  renderProjectCredit = () => {
    if(this.state.projectCredits){
      return this.state.projectCredits
      .map((ele, index) => {
        return (
          <h1 key={index}>{ele}</h1>
        )
      })
    }
  }

  renderButtonCloseInfo = () => {
    if(this.state.isDisplayedProjectInfo){
      return (
        <div
          onClick={this.handleOnDocumentBottom}
          className="button_close_info">
          CLOSE INFO AND SEE IMAGES
        </div>
      )
    }else{
      return null;
    }
  }


     horizontalPageDesktop = () => {
       if(this.props.isSeeAll){
         return null;
       }else{
         if(this.state.renderProjectInfo){
           return (
             <div>
               <div id="container">
                 {this.renderProjectMedia()}
               </div>
           </div>
           )
         }else{
           return (
               <div>
                 <div
                   id="container_2">
                     <HorizontalScroll
                       pageLock={this.state.isProjectPageLocked}
                       className = { "horizontal_scroll" }
                       reverseScroll = {true}>
                        {this.renderProjectMedia()}
                      </HorizontalScroll>
                 </div>
             </div>
           )
         }
       }
    }


  horizontalPageMobile = () => {
    if(this.props.isSeeAll){
      return null;
    }else{
        return (
          <div>
            <div
              style={{flexDirection: "column"}}
              id="container">
              {this.renderProjectMedia()}
            </div>
        </div>
        )
    }
  }

  isTriggerLighBox = (bool) => {

    this.setState({
      renderLighBox: !this.state.renderLighBox
    }, () => {
      return this.props.isTriggerLighBox
    })
  }


  closeLightBox = () => {
    this.setState({
      renderLighBox: false
    })
  }

  renderProjectMedia = () => {
    if(this.state.projectMedias.length > 1){
      return this.state.projectMedias
      .map((ele, index) => {
          return (
            <div
              key={index}
              className="container_inner">
              <HorizontalMediaContainer
                {...this.state}
                {...this.props}
                isTriggerLighBox={this.isTriggerLighBox}
                dataLink={ele}
                index={index}
                />
            </div>
          )
      })
    }else if(this.state.projectMedias.length === 1){
      return (
        <div className="container_inner_one_img">
            <img src={this.state.projectMedias[0]} />
        </div>
      )
    }else{
      return (
        <div>loading</div>
      )
    }
  }


  handleOnDocumentBottom = () => {
    if(this.state.width > 700){
      this.setState({
        renderProjectInfo: false,
        isProjectPageLocked: true,
        isDisplayedProjectInfo: false
      })
    }else{
      return null;
    }
  }

  renderLighBox = () => {
    const selectedData = this.state.selectedProjectData[0];
    if(this.state.renderLighBox
      && this.state.projectVideoLink.length > 0){

      return (
        <div
          style={{backgroundColor: this.state.selectedRandomColor}}
          onClick={this.closeLightBox}
          className="video_container_lightbox">
            <div className="video_container_lightbox_info">
              <h1>{selectedData.project_name}</h1>
              <h1>close the video</h1>
            </div>
         {this.renderVimeoOrYoutube(this.state.projectVideoLink)}
      </div>
      )
    }else{
      return null;
    }
  }

  renderVimeoOrYoutube = (link) => {
    if(link.length === 9){
    return (
      <Vimeo
         width={640}
         height={480}
         video={this.state.projectVideoLink}
         autoplay
       />
    )
  }else{
    return (
      <YouTube
        video="P7W1DhuhvSo"
      />
    )
  }
}


  renderScrollingIndication = () => {
    if(!this.props.isSeeInfo
      && this.state.projectMedias.length > 1
      &&!this.props.isDisplayedProjectInfo){
      return(
        <div className="scroll_indication">
          <h1 style={{color: this.state.selectedRandomColor}}>
            Scroll normally to see images
          </h1>
        </div>
      )
    }else{
      return null;
    }
  }


  togglehorizontalPageDesktopMobile = () => {
    if(this.state.width > 600){
      return (
        this.horizontalPageDesktop()
      )
    }else{
      return (
        this.horizontalPageMobile()
      )
    }
  };


  toggleMenu = () => {
    if(!this.state.isSeeMenu){
      this.setState({
        isSeeMenu: true
      })
    }else{
      this.setState({
        isSeeMenu: false
      })
    }
  };

  reloadWebsite = () => {
    return window.location = "/"
  };

  renderHeaderHorizontalPage = () => {
    if(this.state.renderProjectInfo || this.state.isDisplayedProjectInfo){
      return null;
    }

    const additionalInfo = this.state.infoPage[0].other_texts.split("¬")

    return (
      <div class="header_container">
        <header>
          <div>
          <h1
            onClick={this.reloadWebsite}>
            Frédérique B. Ste-Marie
          </h1>
          <span
            onClick={this.reloadWebsite}>
            {additionalInfo[1]}
          </span>
          {this.renderHorizontalArrow()}
          </div>
          <div>
            <h1 onClick={this.toggleMenu}>
              <Link to="/index">PROJECT INDEX</Link>
              <span
                onClick={this.displayInfo}>
                  project info
              </span>
            </h1>
          </div>
        </header>
    </div>
    )
  };


  displayInfo = () => {
    this.setState({
      isDisplayedProjectInfo: true
    })
  };


  renderHorizontalArrow = () => {
    if(this.state.width > 600
      && !this.state.isSeeMenu){
      return(
        <span>
            <div className="triangle"></div>
        </span>
      )
    }else{
      return null;
    }
 }



  renderFooterHorizontalPage = () => {
    if(this.state.renderProjectInfo){
      return null;
    }
    return (
      <div className="footer_container">
        <footer>
          <div>
            <h1>
              <Link to="/about">See bio</Link>
            </h1>
          </div>
          <div>
            <h1>
              Montréal, CA: <Clock format={'HH:mm:ss'} ticking={true} timezone={'US/Eastern'} />
            </h1>
          </div>
        </footer>
    </div>
    )
  };

  triggerGoBackMobile = () => {
    if(this.state.width > 600){
      return null;
    }
    return (
      <div className="go_back_mobile_horizontal_page">
        <Link to="/index">
        <span style={{color: this.state.selectedRandomColor}}>
          BACK TO INDEX
        </span>
        </Link>
      </div>
    )
  }

  render() {
      if(!this.state.projectCredits){
        return (
          <div>
            loading
          </div>
        )
      }else{
        return (
          <BottomScrollListener
            offset={10}
            onBottom={this.handleOnDocumentBottom}>
          <div>
            {this.renderHeaderHorizontalPage()}
            {this.renderProjectInfo()}
            {this.togglehorizontalPageDesktopMobile()}
            {this.renderLighBox()}
            {this.renderFooterHorizontalPage()}
            {this.triggerGoBackMobile()}
          </div>
        </BottomScrollListener>
        )
      }
    }
  }


export default HorizontalPage;
