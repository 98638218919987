import React, { Component } from 'react';
import HomePageImageContainer from "./HomePageImageContainer";
import './App.css';
var probe = require('probe-image-size');


class HomePage extends Component {

  constructor(props) {
    super(props);
    this.state = {
      counter: 0,
      img: null,
      randomStylingValueArray: null,
      selectedImage: null,
      selectedImageArray: [],
      positionOfTheImage: null,
      selectedStyling: null,
      backgroundColor: null,
      className: null,
    };
  };

  componentDidMount(){
    if(!this.props.random_color_value
      && this.props.info_page
      && this.props.animsApis){
      return (
        <div>loading</div>
      )
    }else{
      const randomImgStyling = this.props.animsApis;
      const imgProps = this.props.home_page[0].img_link.split("¬");
      const additionalInfo = this.props.info_page[0].other_texts.split("¬")
      let colorArray = this.props.random_color_value;
      let randomBackgroundColor = colorArray[Math.floor(colorArray.length * Math.random())];

      const returnRandomStyling = (arrayOfImages, arrayOfStyling) => {
        let finalArray = [];
        for(var i =0; i< arrayOfImages.length; i++){
          let imgStyling = arrayOfStyling[Math.floor(arrayOfStyling.length * Math.random())];
          finalArray.push(
            {
              imgStyling: imgStyling,
            }
          );
        }
        return finalArray;
      }
      this.setState({
        img: imgProps,
        additionalInfo: additionalInfo,
        randomStylingValueArray: returnRandomStyling(imgProps, randomImgStyling),
        backgroundColor: randomBackgroundColor
      })
    }
  };



  toggleSlider = (event) => {

    const actualImage = {
      img: this.state.img[this.state.counter],
      screenX: event.clientX,
      screenY: event.clientY
    }

    let colorArray = this.props.random_color_value;
    let randomBackgroundColor = colorArray[Math.floor(colorArray.length * Math.random())];


    probe(actualImage.img)
    .then(result => {


      let positionOfTheImage = {
        left:  actualImage.screenX - result.width/2,
        top: actualImage.screenY - result.height/2
      };


      if(this.state.counter === this.state.img.length-1){
        this.setState({
          counter: 0
        }, () => {

          let imgData = {
            positionOfTheImage,
            selectedImage: actualImage,
            selectedStyling: this.state.randomStylingValueArray[this.state.counter]
          }

          this.setState({
            backgroundColor: randomBackgroundColor,
            selectedImage: actualImage,
            selectedImageArray: [...this.state.selectedImageArray, imgData],
            positionOfTheImage: positionOfTheImage,
            selectedStyling: this.state.randomStylingValueArray[this.state.counter]
          })
        })
      }else if(this.state.counter === 1){
            this.setState({
              counter: this.state.counter+2
         }, () => {

           let imgData = {
             positionOfTheImage,
             selectedImage: actualImage,
             selectedStyling: this.state.randomStylingValueArray[this.state.counter]
           }

           this.setState({
             backgroundColor: randomBackgroundColor,
             selectedImage: actualImage,
             selectedImageArray: [...this.state.selectedImageArray, imgData],
             positionOfTheImage: positionOfTheImage,
             selectedStyling: this.state.randomStylingValueArray[this.state.counter]
           }, () => {
             return this.props.isFullBackgroundHomePage();
           })
         })
      }else{
        this.setState({
          counter: this.state.counter+1
        }, () => {

          let imgData = {
            positionOfTheImage,
            selectedImage: actualImage,
            selectedStyling: this.state.randomStylingValueArray[this.state.counter]
          }

        this.setState({
          backgroundColor: randomBackgroundColor,
          selectedImage: actualImage,
          selectedImageArray: [...this.state.selectedImageArray, imgData],
          positionOfTheImage: positionOfTheImage,
          selectedStyling: this.state.randomStylingValueArray[this.state.counter],
        })
      })
      }
    }).then(() => {
      return this.props.setSelectedRandomColor(this.state.backgroundColor)
    })
  }


renderHomePageImageContainer = () => {
  return (
    <HomePageImageContainer
      toggleSlider={this.toggleSlider}
      width={this.props.width}
      {...this.state}
    />
  )
}

  render() {

    if(!this.props
    && !this.state.backgroundColor
    && !this.state.randomStylingValueArray){
      return (
        <div className="loading_screen" />
      )
      }else{
          return (
          <div>
            <div
              style={{backgroundColor: this.state.backgroundColor}}
              id="homepage_container"
              className={this.state.className}>
                {this.renderHomePageImageContainer()}
            </div>
          </div>
        )
    }
  }
}

export default HomePage;
