import React, { Component } from 'react';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import axios from 'axios';
import Header from './Header';
import HomePage from "./HomePage"
import Footer from './Footer';
import HorizontalPage from './HorizontalPage';
import SeeInfo from "./SeeInfo";
import SeeMenu from "./SeeMenu"
import './App.css';

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      resetCounter: false,
      testData: null,
      actualHashLink: null,
      isHomePage: true,
      isFullBackgroundHomePage: true,
      isSeeAll: false,
      isSeeMenu: false,
      isSeeselectedDataTitle: false,
      selectedDataTitle: null,
      selectedData: null,
      selectedRandomColor: null,
      selectedDataText: null,
      horizontalPage: false,
      isDisplayedProjectInfo: true,
      home_page: null,
      home_page_selected_images: null,
      info_page: null,
      index_page: null,
      random_color_value: null,
      projectsList: null,
      hoveredData: null,
      toggleBackToTop: false,
      width: null,
      animsApis: [
        {scale: "scale(0.5)", animation: "6s ease 0s infinite normal none running cirle_anim"},
        {scale: "scale(0.3)", animation: "8s ease 0s infinite normal none running polygon_anim"},
        {scale: "scale(0.4)", animation: "4s ease 0s infinite normal none running clip"},
        {scale: "scale(0.6)", animation: "6s ease 0s infinite normal none running cirle_anim"},
        {scale: "scale(0.3)", animation: "2s ease 0s infinite normal none running polygon_anim"},
        {scale: "scale(0.5)", animation: "4s ease 0s infinite normal none running clip"}
      ]
    };
  };

   componentDidMount(){
    window.addEventListener('resize', this.updateWindowDimensions);
    this.updateWindowDimensions();
    this.targetElement = document.querySelector("#body");

     axios.get("https://sheet.best/api/sheets/a67fbb18-7803-4beb-a19a-e11c1623eba0")
     .then((response) => {
       const data = response.data;
       this.setState({
         data,
         testData: data[0]
       }, () => {
         const colors = data.filter((ele) => {return ele.website_section === "random_color_value"});
         const randomColorValueArray = colors[0].project_name.split("¬");

         this.setState({
          info_page: data.filter((ele) => {return ele.website_section === "info_page"}),
          index_page: data.filter((ele) => {return ele.website_section === "index_page"}),
          home_page: data.filter((ele) => {return ele.website_section === "home_page"}),
          random_color_value: randomColorValueArray,
        })
      })
     })
     .catch(function (error) {
       console.log(error);
     });
  };

  updateWindowDimensions = () => {
    this.setState({
      width: window.innerWidth
    });
  };

  toggleMenu = () => {
    this.setState({
      isSeeMenu: !this.state.isSeeMenu,
    }, () => {
      if(this.state.isSeeMenu){
        this.setState({
          selectedData: null,
          isHomePage: false
        })
      }
    })
  };

  toggleMenuEnableHomePage = () => {
    this.setState({
      isSeeMenu: !this.state.isSeeMenu,
    }, () => {
      if(!this.state.isSeeMenu){
        this.setState({
          selectedData: null,
          isHomePage: true,
          isFullBackgroundHomePage: true,
          horizontalPage: false,
        })
      }
    })
  };

  triggerSeeAllSection = () => {
    let colorArray = this.state.random_color_value;
    let randomBackgroundColor = colorArray[Math.floor(colorArray.length * Math.random())];
    this.setState({
      isSeeAll: !this.state.isSeeAll,
      horizontalPage: false,
      isHomePage: false,
      isSeeMenu: false,
      selectedRandomColor: randomBackgroundColor
    })
  };

  sendTitleToParent = (title) => {
    this.setState({
      title
    })
  }
  renderMenu = () => {
    if(this.state.isSeeMenu
      && !this.state.index_page !== null){
      return (
        <SeeMenu
        toggleHorizontalSection={this.toggleHorizontalSection}
        {...this.state}
        />
      )
    }else{
      return null;
    }
  };


  enableProjectInfo = () => {
    this.setState({
      isDisplayedProjectInfo: true
    })
  }


  toggleHorizontalSection = (ele) => {

    let colorArray = this.state.random_color_value;
    let randomBackgroundColor = colorArray[Math.floor(colorArray.length * Math.random())];

    this.setState({
      selectedData: ele,
      isDisplayedProjectInfo: true,
      hoveredData: null,
      isSeeAll: false,
      isHomePage: false,
      isSeeMenu: false,
      horizontalPage: true,
      selectedRandomColor: randomBackgroundColor
    }, () => {
      document.body.style.backgroundColor = this.state.selectedRandomColor;
    })
  }

  hoverImages = (hoveredData) => {
    this.setState({
      hoveredData
    })
  }


  renderHomePage = () => {
    if(this.state.isHomePage
      && this.state.home_page !== null){
      return (
        <HomePage
          {...this.state}
          setSelectedRandomColor={this.setSelectedRandomColor}
          isFullBackgroundHomePage={this.toggleFullBackgroundHomePage}
          triggerSeeAllSection={this.triggerSeeAllSection}
        />
      )
    }else{
      return null;
    }
  }

  renderHeader = () => {
    return (
      <Header
        {...this.state}
        toggleMenu={this.toggleMenu}
        toggleMenuEnableHomePage={this.toggleMenuEnableHomePage}
        toggleProjects={this.toggleProjects}
        enableProjectInfo={this.enableProjectInfo}
      />
    )
  }

  renderFooter = () => {
    return (
      <Footer
        {...this.state}
        triggerSeeAllSection={this.triggerSeeAllSection}
        triggerSeeInfoSection={this.triggerSeeInfoSection}
        seeInfo={this.seeInfo}
      />
    )
  }

  setSelectedRandomColor = (value) => {
    this.setState({
      selectedRandomColor: value
    })
  }

  toggleFullBackgroundHomePage = () => {
    this.setState({
      isFullBackgroundHomePage: false
    })
  }


  render() {
        return (
          <div>
            {this.renderHomePage()}
              <div>
              {this.renderMenu()}
              {this.renderHeader()}
              {this.renderFooter()}
              </div>
          </div>
    )
  }
}

export default Home;
