import React, { Component } from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import Clock from 'react-live-clock';
import './App.css';

class Footer extends Component {

  constructor(props) {
    super(props);
    this.state = {
      time: new Date().toLocaleString()
    };
  }

  render() {
      if(!this.props){
        return(
          <div>loading</div>
        )
      }else{

        const renderSeeInfo = () => {
          if(!this.props.isSeeInfo){
            return (
              <h1>
                <Link to="/about">See bio</Link>
              </h1>
            )
          }else{
            return null;
          }
        }


        const renderSeeAll = () => {
          if(!this.props.isSeeInfo
            && !this.props.isSeeMenu){
            return (
              <h1>
                Montréal, CA: <Clock format={'HH:mm:ss'} ticking={true} timezone={'US/Eastern'} />
              </h1>
            )
          }else{
            return null;
          }
        }



        if(this.props.isFullBackgroundHomePage === true){
          return null
        }else{
          return (
            <div className="footer_container">
              <footer>
                <div>
                    {renderSeeInfo()}
                </div>
                <div>
                    {renderSeeAll()}
                </div>
              </footer>
            </div>
          )
        }
      }
  }
}

export default Footer;
