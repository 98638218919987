import React, {Component, Fragment} from 'react'
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";
import Home from "./Home";
import SeeInfo from "./SeeInfo";
import SeeMenu from "./SeeMenu"
import HorizontalPage from "./HorizontalPage"


    export default class App extends Component {
        render() {
            return (
                <Router>
                    <Route exact path='/' component={Home}></Route>
                    <Route exact path='/about' component={SeeInfo}></Route>
                    <Route exact path='/index' component={SeeMenu}></Route>
                    <Route
                      exact path="/projects/:project"
                      component={HorizontalPage}
                    />
                </Router>
            )
        }
    }
