import React, { Component } from 'react';
import axios from 'axios';
import { HashLink as Link } from 'react-router-hash-link';
import './App.css';


class seeMenu extends Component {

    constructor(props) {
      super(props);
      this.state = {
        displayedImagesArray: null,
        isOnimagesSectionContent: false,
        indexPage: null,
        infoPage: null,
        width: null,
        animsApis: [
         {scale: "scale(0.5)", animation: "6s ease 0s infinite normal none running cirle_anim"},
         {scale: "scale(0.3)", animation: "8s ease 0s infinite normal none running polygon_anim"},
         {scale: "scale(0.4)", animation: "4s ease 0s infinite normal none running clip"},
         {scale: "scale(0.6)", animation: "6s ease 0s infinite normal none running cirle_anim"},
         {scale: "scale(0.3)", animation: "2s ease 0s infinite normal none running polygon_anim"},
         {scale: "scale(0.5)", animation: "4s ease 0s infinite normal none running clip"}
       ]
      };
    };

    componentDidMount(){

      window.addEventListener('resize', this.updateWindowDimensions);
      this.updateWindowDimensions();


      axios.get("https://sheet.best/api/sheets/a67fbb18-7803-4beb-a19a-e11c1623eba0")
      .then((response) => {
        const data = response.data;
        const colors = data.filter((ele) => {return ele.website_section === "random_color_value"});
        const indexPage = data.filter((ele) => {return ele.website_section === "index_page"});
        const infoPage = data.filter((ele) => {return ele.website_section === "info_page"});
        const randomColorValueArray = colors[0].project_name.split("¬");
        let randomBackgroundColor = randomColorValueArray[Math.floor(randomColorValueArray.length * Math.random())];

        const randomImgStyling = this.state.animsApis.map((ele, index) => {
          return ele.animation
        })

        const displayedImagesArray = indexPage.map((ele, index) => {
           let imgStyling = randomImgStyling[Math.floor(randomImgStyling.length * Math.random())];
           return {
             img: ele.img_of_the_project_to_feature,
             specs: ele.specsoftheimgtofeature,
             animation: imgStyling
           }
         })

         this.setState({
            displayedImagesArray,
            indexPage,
            infoPage,
            selectedRandomColor: randomBackgroundColor
        })

      })
    };

    updateWindowDimensions = () => {
      this.setState({
        width: window.innerWidth
      });
    };


    handleMouseEnterTextSection = () => {
      this.setState({
        isOnimagesSectionContent: true
      })
    }

    handleMouseLeaveTextSection = () => {
      this.setState({
        isOnimagesSectionContent: false
      })
    }


    renderBouncingBallOnScreen = () => {
       if(!this.state.isOnimagesSectionContent){
         return this.renderBouncingBall("running", "visible")
       }else{
         return this.renderBouncingBall("paused", "hidden")
       }
     };

   renderBouncingBall = (state, visibility) => {
     const style = {
       animationPlayState: state,
       visibility: visibility
     }
       return (
         <div className="box">
           <b style={style}/>
         </div>
     )
   };

   renderMenuContentImages = () => {
        if(this.state.isOnimagesSectionContent){

          if(this.state.displayedImageSpecs === "horizontal"){
          
          // this is coming from the cms; it's because the specs value is undefined;

            return (
              <img
                className={this.state.displayedImageSpecs}
                alt={this.state.displayedImage}
                src={this.state.displayedImage}
                style={{animation: this.state.displayedImageAnimation}}
                />
            )
          }else if(this.state.displayedImageSpecs === "vertical"){
            const style = {
              height: "75%",
              width: "auto",
              animation: this.state.displayedImageAnimation
            }
            return (
              <img
                className={this.state.displayedImageSpecs}
                alt={this.state.displayedImage}
                src={this.state.displayedImage}
                style={style}
                />
            )
          }else{
            return null;
          }
        }else{
          return null;
        }
    };


  renderMenuContentText = () => {
      if(!this.state.indexPage){
        return null;
      }else{
        const menuInfo = this.state.indexPage
        .map((ele, index) => {

          let param = ele.project_url;
          return (
            <section
              key={index}
              className="menu_main_container_cell"
              onMouseEnter={() => this.handleMouseEnterImages(index)}
              onMouseLeave={this.handleMouseLeaveImages}>
              <div className="menu_main">
              <div>
                <Link to={`/projects/${param}`}>
                  <h1>
                    {ele.project_name}
                  </h1>
                  <div className="menu_main_secondary">
                    <h2>
                      {ele.project_type}
                    </h2>
                  </div>
                  </Link>
                </div>
              </div>
            </section>
          )
        })
        return menuInfo;
      }
    };


  handleMouseEnterImages = (index) => {

    console.log(index, this.state);

    let selectedRandomColor = this.state.selectedRandomColor;
    this.setState({
      displayedImage: this.state.displayedImagesArray[index].img,
      displayedImageSpecs: this.state.displayedImagesArray[index].specs,
      displayedImageAnimation: this.state.displayedImagesArray[index].animation,
      selectedRandomColor,
    })
  }


  renderMenuContentTextMobile = () => {
     if(this.state.displayedImagesArray){

       const menuInfo = this.state.indexPage
       .map((ele, index) => {

         const styleImgMobile = {
           width: "100%",
           animation: this.state.displayedImagesArray[index].animation
         };

         let param = ele.project_url;

         return (
           <section
             key={index}
             className="menu_main_container_cell">
             <div className="menu_main">
              <Link to={`/projects/${param}`}>
               <h1>
                 {ele.project_name}
               </h1>
               <div className="menu_main_secondary">
                 <h2>
                   {ele.project_type}
                 </h2>
               </div>
               </Link>
             </div>
             <img
               style={styleImgMobile}
               alt={ele.img_of_the_project_to_feature}
               src={ele.img_of_the_project_to_feature}
             />
           </section>
         )
       })
       return menuInfo;
     }else{
       return null;
     }
   }


  renderMenuOnQuery = () => {
  if(this.state.width > 700){
    return (
      <div
        style={{backgroundColor: "black"}}
        className="menu_master_container">
          <div
              className="menu_main_container_outer"
              onMouseEnter={this.handleMouseEnterTextSection}
              onMouseLeave={this.handleMouseLeaveTextSection}>
              {this.renderMenuContentText()}
          </div>
          <div
            style={{backgroundColor: this.state.selectedRandomColor}}
            className="menu_img_container">
            {this.renderMenuContentImages()}
            {this.renderBouncingBallOnScreen()}
          </div>
      </div>
    )
  }else{
    return (
      <div
        style={{backgroundColor: this.state.selectedRandomColor}}
        className="menu_master_container">
          <div className="menu_main_container_outer">
              {this.renderMenuContentTextMobile()}
          </div>
      </div>
    )
  }
}


renderHeaderMenu = () => {
  if(!this.state.infoPage){
    return null;
  }
  const additionalInfo = this.state.infoPage[0].other_texts.split("¬")
  return (
    <div className="header_container">
      <header>
        <div>
        <h1
          onClick={this.reloadWebsite}>
          Frédérique B. Ste-Marie
        </h1>
        <span
          onClick={this.reloadWebsite}>
          {additionalInfo[1]}
        </span>
        </div>
        <div>
          <h1 onClick={this.toggleMenu}>
            <Link to="/">CLOSE INDEX</Link>
          </h1>
        </div>
      </header>
  </div>
  )
}

renderFooterMenu = () => {
  if(!this.state.infoPage){
    return null;
  }
  return (
    <div className="footer_container">
      <footer>
        <div>
          <h1>
            <Link to="/about">See bio</Link>
          </h1>
        </div>
      </footer>
  </div>
  )
};



  render() {
    return(
      <div>
        {this.renderHeaderMenu()}
        {this.renderMenuOnQuery()}
        {this.renderFooterMenu()}
      </div>
    )
    }
  }

export default seeMenu;
