import React, { Component } from 'react';
import './App.css';

class HomePageImageContainer extends Component {

  constructor(props) {
    super(props);
    this.state = {
      randomStylingValue: null,
      randomColorValue: null
    };
  };

  renderImgContainer = () => {
    if(!this.props.additionalInfo){
      return (
        <div className="loading_screen" />
      )
    }else{
      if(this.props.selectedImage === null){
        return (
          <div
            className="homepage_img_inner_container homepage_text">
              <h1>{this.props.additionalInfo[0]}</h1>
              <span>{this.props.additionalInfo[1]}</span>
          </div>
        )
      }else{
        return (
          <div
            className="homepage_img_inner_container">
            <div className="homepage_img_container">
              {this.renderImg()}
            </div>
          </div>
        )
      }
    }
  }

  renderImg = () => {
    if(this.props.selectedImage){

      const stackOfImages = this.props.selectedImageArray
      .map((ele, index) => {

        const randomAnimation =  ele.selectedStyling.imgStyling.animation;
        const randomScale =  ele.selectedStyling.imgStyling.scale;

        const positionOfTheImage = ele.positionOfTheImage;

        const randomStyle = {
          position: "fixed",
          transform: randomScale,
          left: positionOfTheImage.left,
          top: positionOfTheImage.top,
          animation: randomAnimation
        }

        const randomStyleMobile = {
          position: "fixed",
          animation: randomAnimation,
          width: "90vw"
        }

        if(this.props.width > 600){
          return (
            <img
              style={randomStyle}
              alt={ele.selectedImage.img}
              src={ele.selectedImage.img}
            />
          )
        }else{
          return (
            <img
              style={randomStyleMobile}
              alt={ele.selectedImage.img}
              src={ele.selectedImage.img}
            />
          )
        }
      })
      return stackOfImages;
    }
  }
  render() {

      if(!this.props){
        return (
          <div className="loading_screen" />
        )
      }else{
        return(
          <div
            onClick={this.props.toggleSlider}
            className="homepage_image_container">
              {this.renderImgContainer()}
          </div>
        )
      }
  }
}

export default HomePageImageContainer;
